





















import { Component, Vue } from "vue-property-decorator";
import AppFormWrapper from "@/layouts/AppFormWrapper.vue";
import TokenService from "@/services/TokenService";
import { getFirstQueryValue } from "@/utils/queryParams";

enum Status {
  "loading",
  "error",
  "invalidToken",
  "ready",
}

@Component({
  components: {
    AppFormWrapper,
  },
})
export default class DownloadExposeView extends Vue {
  Status = Status;
  status: Status = this.Status.loading;

  get token(): string {
    return getFirstQueryValue(this.$route.query.token) ?? "";
  }

  async initialLoad(): Promise<void> {
    try {
      if (this.token === "") {
        this.status = this.Status.invalidToken;
        return;
      }

      const tokenValidity = await TokenService.isValid(this.token);
      if (tokenValidity.expired) {
        this.status = this.Status.invalidToken;
        return;
      }

      this.status = this.Status.ready;
      this.downloadFile();
    } catch (e) {
      this.status = this.Status.error;
    }
  }

  downloadFile(): void {
    window.open(`/api/v2/files/expose?token=${this.token}`, "_self");
  }

  created(): void {
    this.initialLoad();
  }
}
